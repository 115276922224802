import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { formatPrice } from '@/lib/utils';
import { Calendar, Coins, Ticket, User2 } from 'lucide-react';
import { BasicTooltip } from '@/components/ui/tooltip';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useCurrentOrganization } from '@/hooks/apiHooks';
import { useGetStatsQuery } from '@/services/statApi';
import { RevenueChart } from '@/components/RevenueChart';
import { useGetTicketsQuery } from '@/services/ticketsApi';

export const AdminHome = (): JSX.Element => {
    const currentOrganization = useCurrentOrganization();
    const { data: statData, isLoading: statIsLoading } = useGetStatsQuery({
        organizationId: currentOrganization?.id,
    });
    const { data: ticketsData, isLoading: ticketIsLoading } = useGetTicketsQuery({
        organizationId: currentOrganization?.id,
    });

    if (statIsLoading || ticketIsLoading) {
        return <CenteredSpinner />;
    }
    return (
        <div className="flex w-full flex-col gap-4 p-8 pt-6">
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                <BasicTooltip tooltip="Total revenue from sales. Does not include fees.">
                    <Card>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">Total Revenue</CardTitle>
                            <Coins className="h-5 w-5" />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold pb-2">
                                {formatPrice(statData?.salesRevenue ?? 0)} SEK
                            </div>
                        </CardContent>
                    </Card>
                </BasicTooltip>
                <BasicTooltip tooltip="Total number of unique tickets sold.">
                    <Card>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">Sold Tickets</CardTitle>
                            <Ticket className="h-5 w-5" />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold pb-2">{statData?.soldTickets ?? 0}</div>
                        </CardContent>
                    </Card>
                </BasicTooltip>
                <BasicTooltip tooltip="Total number of unique users that have bought tickets.">
                    <Card>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">Users</CardTitle>
                            <User2 className="h-5 w-5" />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold pb-2">{statData?.users ?? 0}</div>
                        </CardContent>
                    </Card>
                </BasicTooltip>
                <BasicTooltip tooltip="Total created unique events, with at least one ticket sold.">
                    <Card>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">Events</CardTitle>
                            <Calendar className="h-5 w-5" />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold pb-2">{statData?.events ?? 0}</div>
                        </CardContent>
                    </Card>
                </BasicTooltip>
            </div>
            <div className="flex gap-4 flex-col lg:flex-row w-full">
                <div className="flex-1 flex-grow[1]">
                    <RevenueChart salesTimeSeries={statData?.salesTimeSeries ?? []} />
                </div>
                <Card className="w-full lg:flex-grow[2] lg:w-auto">
                    <CardHeader>
                        <CardTitle>Recent Sales</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="flex flex-col gap-2">
                            {ticketsData?.data
                                .filter((ticket) => ticket.actualPrice > 0)
                                .slice(0, 8)
                                .map((ticket) => (
                                    <div
                                        key={ticket.id}
                                        className="flex flex-col sm:items-center sm:gap-8 lg:gap-12 sm:flex-row justify-between"
                                    >
                                        <div className="flex flex-col gap-1">
                                            <p className="text-sm font-medium leading-none">{ticket.eventTitle}</p>
                                            <p className="text-sm text-muted-foreground">{ticket.eventItemName}</p>
                                        </div>
                                        <div className="font-medium">+ {formatPrice(ticket.actualPrice)} SEK</div>
                                    </div>
                                ))}
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};
