import { useRef, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import jsQR from 'jsqr';
import { useConsumeTicketMutation } from '@/services/ticketsApi';
import { errorToast, successToast } from '@/components/ui/use-toast';
import { Spinner } from '@/components/ui/spinner';
import { isLabbError } from '@/types/Error';
import { useGetEventByIdQuery } from '@/services/eventsApi';
import { useFunction0Ref } from '@/lib/utils';

export const ScanPage: React.FC = () => {
    const { eventId } = useParams() as { eventId: string };
    const { data, isLoading } = useGetEventByIdQuery(eventId);

    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [prevScannedCode, setPrevScannedCode] = useState<string>();
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const [consumeTicket] = useConsumeTicketMutation();

    useEffect(() => {
        let videoStream: MediaStream | undefined;

        const startVideo = async () => {
            try {
                videoStream = await navigator.mediaDevices.getUserMedia({
                    video: { facingMode: 'environment' },
                });
                if (videoRef.current) {
                    videoRef.current.srcObject = videoStream;
                    videoRef.current.setAttribute('playsinline', 'true'); // For iOS Safari
                    await videoRef.current.play();
                    requestAnimationFrame(tick);
                }
            } catch (err) {
                console.error('Error accessing camera: ', err);
                alert('Unable to access camera. Please check permissions.');
            }
        };

        startVideo();

        return () => videoStream?.getTracks().forEach((track) => track.stop());
    }, []);

    // uses a function ref instead of a function to have fresh state values
    const tick = useFunction0Ref(() => {
        if (videoRef.current && videoRef.current.readyState === videoRef.current.HAVE_ENOUGH_DATA) {
            const videoWidth = videoRef.current.videoWidth;
            const videoHeight = videoRef.current.videoHeight;
            const canvas = canvasRef.current?.getContext('2d');

            if (!canvas || !canvasRef.current) {
                return;
            }

            canvasRef.current.width = videoWidth;
            canvasRef.current.height = videoHeight;

            canvas.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);

            const imageData = canvas.getImageData(0, 0, videoWidth, videoHeight);
            const code = jsQR(imageData.data, videoWidth, videoHeight);

            // Only process if we're not already processing and the code is new
            if (code?.data && !isProcessing && prevScannedCode !== code.data) {
                handleScan(code.data);
            }
        }
        requestAnimationFrame(tick);
    });

    const handleScan = async (detectedCode: string) => {
        setPrevScannedCode(detectedCode);
        // Check if detectedCode is a valid UUID
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        const isValidUUID = uuidRegex.test(detectedCode);

        if (!isValidUUID) {
            window.alert('Scanned code is not a valid ticket identifier.');
            return;
        }

        if (!window.confirm('Do you want to consume this ticket?')) {
            setPrevScannedCode(undefined);
            return;
        }

        setIsProcessing(true);
        consumeTicket(detectedCode)
            .unwrap()
            .then(() => successToast('Ticket consumed successfully.'))
            .catch((err) => isLabbError(err) && errorToast('Failed to consume ticket: ' + err.data.message))
            .finally(() => setIsProcessing(false));
    };

    if (isLoading || !data) {
        return (
            <div className="flex justify-center items-center h-full w-full">
                <Spinner size="lg" />
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-full p-4">
            <h1 className="text-2xl font-bold text-gray-800 mb-6">Scanning for {data?.title}</h1>
            <div className="relative w-full max-w-md">
                <video ref={videoRef} className="hidden" playsInline />
                <canvas ref={canvasRef} className="w-full rounded-lg" />
                {isProcessing && <Spinner />}
            </div>
        </div>
    );
};
