import { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Card, CardContent } from '@/components/ui/card';
import type { Receipt } from '@/types/Receipt';
import { formatDate, formatPrice } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { InfoIcon } from 'lucide-react';

export function ReceiptDetailsModal({ receipt }: { receipt: Receipt }): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);

    const totalAmount = receipt.tickets.reduce((acc, ticket) => acc + ticket.actualPrice, 0);

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <Button variant="outline" className="gap-2" onClick={() => setIsOpen(true)}>
                    <InfoIcon className="h-4 w-4" />
                    Details
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Receipt #{receipt.id}</DialogTitle>
                </DialogHeader>
                <Card className="w-full">
                    <CardContent className="space-y-3 pt-4">
                        <div className="grid grid-cols-2 gap-2">
                            <div>
                                <p className="text-sm text-muted-foreground">User</p>
                                <p className="font-medium">
                                    {receipt.user.firstName} {receipt.user.lastName}{' '}
                                </p>
                            </div>
                            <div>
                                <p className="text-sm text-muted-foreground">Email</p>
                                <p className="font-medium">{receipt.user.email}</p>
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-2">
                            <div>
                                <p className="text-sm text-muted-foreground">Total Amount</p>
                                <p className="font-semibold text-lg">{formatPrice(totalAmount)} SEK</p>
                            </div>
                            <div>
                                <p className="text-sm text-muted-foreground">Date</p>
                                <p>{formatDate(receipt.createdAt)}</p>
                            </div>
                        </div>

                        <div className="border-t pt-3">
                            <h3 className="text-sm font-semibold mb-2 text-muted-foreground">Tickets</h3>
                            <div className="space-y-2">
                                {receipt.tickets.map((ticket) => (
                                    <div key={ticket.id} className="flex justify-between border-b pb-2 last:border-b-0">
                                        <span className="text-sm">
                                            {ticket.eventTitle} - {ticket.eventItemName}
                                        </span>
                                        <span className="font-medium">{formatPrice(ticket.actualPrice)} SEK</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </DialogContent>
        </Dialog>
    );
}
