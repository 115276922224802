import { isKey, isString } from '@/lib/typeNarrowing';
import type { Role } from './Role';

export interface User {
    firstName: string;
    lastName: string;
    email: string;
    id: string;
    foodPreferences: string[];
}

export interface UserResponse extends User {
    roles: Role[];
    verified: boolean;
}

export interface UserFilter {
    pageNumber?: number;
    pageSize?: number;
}

export interface CreateUser {
    email: string;
    firstName: string;
    lastName: string;
    captchaToken: string;
}

export interface EditUser {
    id: string;
    firstName: string;
    lastName: string;
    foodPreferences: string[];
}

export interface SignInRequest {
    email: string;
    captchaToken: string;
}

export interface OTPRequest {
    email: string;
    otp: string;
}

export interface AuthResponse {
    refreshToken: string;
    token: string;
}

export const isAuthResponse = (response: unknown): response is AuthResponse => {
    return (
        isKey(response, 'refreshToken') &&
        isKey(response, 'token') &&
        isString(response.refreshToken) &&
        isString(response.token)
    );
};
