import { useState, useRef, useCallback, useEffect, type MutableRefObject } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

interface UseRecaptchaReturn {
    captchaToken: string | null;
    recaptchaRef: MutableRefObject<ReCAPTCHA | null>;
    handleRecaptchaChange: (token: string | null) => void;
}

const useRecaptcha = (): UseRecaptchaReturn => {
    const [captchaToken, setCaptchaToken] = useState<string | null>(null);
    const recaptchaRef = useRef<ReCAPTCHA | null>(null);

    const handleRecaptchaChange = useCallback((token: string | null) => {
        setCaptchaToken(token);
    }, []);

    useEffect(() => {
        const refreshCaptcha = () => {
            if (recaptchaRef.current && captchaToken) {
                recaptchaRef.current.reset();
                setCaptchaToken(null);
            }
        };

        let tokenRefreshTimeout: NodeJS.Timeout | null = null;

        if (captchaToken) {
            tokenRefreshTimeout = setTimeout(refreshCaptcha, 110000); // 110 seconds
        }

        return () => {
            if (tokenRefreshTimeout) {
                clearTimeout(tokenRefreshTimeout);
            }
        };
    }, [captchaToken]);

    return { captchaToken, recaptchaRef, handleRecaptchaChange };
};

export default useRecaptcha;
