import type { EventItemInput } from '@/types/EventItem';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Switch } from '../ui/Switch';
import { Button } from '../ui/button';
import { FormDatePicker, FormInput, FormMultiSelect } from '../ui/FormHelpers';
import { useCurrentOrganizationRoles } from '@/hooks/apiHooks';
import { Form } from '../ui/form';
import { formatDateTimeForForm, formatPrice, toISO } from '@/lib/utils';
import { type OptionType } from '../ui/multi-select';
import { BasicTooltip } from '../ui/tooltip';

interface EventItemModalProps {
    onConfirm: (e: EventItemInput) => void;
    onCancel: () => void;
    eventItem?: EventItemInput;
    publishDate?: string;
    endDate?: string;
}

interface FormInputProps extends EventItemInput {
    roleOptions: OptionType[];
}

export const EventItemModal: React.FC<EventItemModalProps> = (props) => {
    const { onConfirm, onCancel, eventItem, publishDate, endDate } = props;
    const [allowTicketSwitching, setAllowTicketSwitching] = useState(!!eventItem?.lastSwitchDate);
    const [hasBuyLimit, setHasBuyLimit] = useState(!!eventItem?.limit);
    const [isFree, setIsFree] = useState(!eventItem?.price);
    const [allowCustomPrice, setAllowCustomPrice] = useState(eventItem?.hasMinimumPrice ?? false);
    const roleResponse = useCurrentOrganizationRoles();
    const roles = roleResponse.roles;

    const defaultValuesForForm = {
        name: eventItem?.name,
        limit: eventItem?.limit || undefined,
        price: eventItem?.price ? Number(formatPrice(eventItem.price)) : undefined,
        expirationDate: formatDateTimeForForm(eventItem?.expirationDate) || formatDateTimeForForm(endDate),
        lastSwitchDate: formatDateTimeForForm(eventItem?.lastSwitchDate),
        releaseDate: formatDateTimeForForm(eventItem?.releaseDate) || formatDateTimeForForm(publishDate),
        allowCustomPrice: eventItem?.hasMinimumPrice,
        roleOptions:
            eventItem?.purchasePermittedRoleIds.map((roleId) => {
                const role = roles.find((r) => r.id === roleId);
                const option: OptionType = { value: roleId, label: role?.title || 'No role title' };
                return option;
            }) ?? [],
    };

    const form = useForm<FormInputProps>({
        defaultValues: defaultValuesForForm,
    });
    useEffect(() => {
        form.reset(defaultValuesForForm);
    }, [roleResponse.isLoading]);

    const handleSubmit = form.handleSubmit;
    const isLoading = form.formState.isLoading || roleResponse.isLoading;
    const control = form.control;

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-background/80 backdrop-blur-sm bg-opacity-40 flex justify-center items-center z-50">
            <div
                className="w-[460px] p-4 rounded-lg space-y-4 bg-accent shadow-lg border"
                onClick={(e) => e.stopPropagation()}
            >
                <Form {...form}>
                    <form
                        onSubmit={handleSubmit((data) => {
                            const filteredData: EventItemInput = {
                                name: data.name,
                                lastSwitchDate: allowTicketSwitching ? toISO(data.lastSwitchDate) : null,
                                limit: hasBuyLimit ? data.limit : null,
                                price: isFree ? 0 : data.price * 100,
                                expirationDate: toISO(data.expirationDate),
                                releaseDate: toISO(data.releaseDate),
                                purchasePermittedRoleIds: data.roleOptions.map((option) => option.value),
                                checkPurchasePermissions: data.roleOptions.length > 0,
                                hasMinimumPrice: allowCustomPrice,
                            };
                            onConfirm(filteredData);
                        })}
                        className="space-y-4"
                    >
                        <div className="flex justify-between flex-col items-center gap-2">
                            <FormInput id="name" label="Name" type="text" control={control} />
                            <FormDatePicker
                                control={control}
                                id="releaseDate"
                                label="Release Date"
                                placeholder="Release Date"
                            />
                            <FormDatePicker
                                control={control}
                                id="expirationDate"
                                label="Expiration Date"
                                placeholder="Expiration Date"
                            />
                            <FormMultiSelect
                                control={control}
                                id="roleOptions"
                                label="Limit to roles"
                                placeholder="Anyone can buy"
                                notRequired
                                hide={roleResponse.isLoading}
                                fadeIn={roleResponse.isLoading}
                                valuesAndNames={roles.map((role) => {
                                    const option: OptionType = { value: role.id, label: role.title };
                                    return option;
                                })}
                            />
                            <div className="flex flex-row gap-4 w-full">
                                <div className="min-w-fit text-lg font-semibold flex flex-col items-left">
                                    Free ticket
                                    <Switch className="py-3" checked={isFree} onClick={() => setIsFree(!isFree)} />
                                </div>
                                {!isFree && (
                                    <>
                                        <FormInput
                                            id="price"
                                            label="Price"
                                            type="number"
                                            control={control}
                                            notRequired={isFree}
                                            step="0.01"
                                            minValue={2.5}
                                            hide={isFree}
                                        />
                                        <div className="min-w-fit text-lg font-semibold flex flex-col items-left">
                                            Allow donations
                                            <BasicTooltip
                                                side="bottom"
                                                tooltip="If checked the buyer can choose to pay more than the set price"
                                            >
                                                <Switch
                                                    className="py-3"
                                                    checked={allowCustomPrice}
                                                    onClick={() => setAllowCustomPrice(!allowCustomPrice)}
                                                />
                                            </BasicTooltip>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="flex gap-2 w-full">
                                <div className="min-w-fit text-lg font-semibold flex flex-col items-left">
                                    Allow Ticket switching
                                    <Switch
                                        className="py-3"
                                        checked={allowTicketSwitching}
                                        onClick={() => setAllowTicketSwitching(!allowTicketSwitching)}
                                    />
                                </div>
                                <FormDatePicker
                                    control={control}
                                    id="lastSwitchDate"
                                    label="Last Switch Date"
                                    placeholder="Date"
                                    notRequired={!allowTicketSwitching}
                                    hide={!allowTicketSwitching}
                                />
                            </div>
                            <div className="flex space-x-6 w-full">
                                <div className="min-w-fit text-lg font-semibold flex flex-col items-left">
                                    Buy limit
                                    <Switch
                                        className="py-3"
                                        checked={hasBuyLimit}
                                        onClick={() => setHasBuyLimit(!hasBuyLimit)}
                                    />
                                </div>
                                <FormInput
                                    id="limit"
                                    label="Limit"
                                    type="number"
                                    control={control}
                                    notRequired={!hasBuyLimit}
                                    minValue={1}
                                    hide={!hasBuyLimit}
                                />
                            </div>
                        </div>
                        <div className="flex justify-end space-x-2">
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    onCancel();
                                }}
                                variant="secondary"
                            >
                                Cancel
                            </Button>
                            <Button type="submit" variant="default">
                                {isLoading ? 'Loading...' : 'Confirm'}
                            </Button>
                        </div>
                    </form>
                </Form>
            </div>
        </div>
    );
};
