'use client';

import { Area, AreaChart, CartesianGrid, XAxis, YAxis, Brush } from 'recharts';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { type ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart';
import { parseISO, formatISO, eachDayOfInterval } from 'date-fns';

interface RevenueChartProps {
    salesTimeSeries: salesEntry[];
}

interface salesEntry {
    date: string;
    revenue: number;
    ticketsSold: number;
}

const chartConfig: ChartConfig = {
    revenue: {
        label: 'Revenue',
        color: 'hsl(var(--chart-1))',
    },
};

const fillMissingDates = (salesTimeSeries: salesEntry[]) => {
    const firstDate = salesTimeSeries[0] && parseISO(salesTimeSeries[0].date);
    if (!firstDate) {
        return [];
    }

    const lastDate = new Date(); // Today's date
    const allDates = eachDayOfInterval({ start: firstDate, end: lastDate });

    const dateMap = new Map(salesTimeSeries.map((entry) => [entry.date.slice(0, 10), entry]));

    const filledData = allDates.map((date) => {
        const dateString = formatISO(date, { representation: 'date' });
        return (
            dateMap.get(dateString) || {
                date: dateString,
                revenue: 0,
                ticketsSold: 0,
            }
        );
    });

    return filledData;
};

export const RevenueChart: React.FC<RevenueChartProps> = ({ salesTimeSeries }) => {
    const filledData = fillMissingDates(salesTimeSeries);

    const chartData = filledData.map((item) => ({
        date: new Date(item.date).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
        }),
        revenue: item.revenue / 100,
    }));

    return (
        <Card>
            <CardHeader>
                <CardTitle>Sales</CardTitle>
            </CardHeader>
            <CardContent>
                <ChartContainer config={chartConfig}>
                    <AreaChart
                        accessibilityLayer
                        data={chartData}
                        margin={{
                            left: 12,
                            right: 12,
                        }}
                    >
                        <CartesianGrid vertical={false} />
                        <XAxis dataKey="date" tickLine={false} axisLine={false} tickMargin={8} />
                        <YAxis tickFormatter={(value) => `SEK ${value}`} domain={[0, 'auto']} />
                        <ChartTooltip cursor={false} content={<ChartTooltipContent indicator="line" />} />
                        <Area
                            dataKey="revenue"
                            type="linear"
                            fill="var(--color-revenue)"
                            fillOpacity={0.4}
                            stroke="var(--color-revenue)"
                        />
                        <Brush dataKey="date" height={30} stroke="var(--color-revenue)" travellerWidth={15} />
                    </AreaChart>
                </ChartContainer>
            </CardContent>
        </Card>
    );
};
