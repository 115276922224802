import 'leaflet/dist/leaflet.css';

import { useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';

import MapLocator from '@/components/Places/MapLocator';
import PlaceCard from '@/components/Places/PlaceCard';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useCurrentOrganizationPlaces } from '@/hooks/apiHooks';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
import { CreatePlace } from '@/components/Places/CreatePlace';
import { PageHeader } from '@/components/PageHeader';

const LUND_COORDINATES: [number, number] = [55.706333, 13.199167];
const DEFAULT_ZOOM = 13;

export const Places: React.FC = () => {
    const { places, isLoading } = useCurrentOrganizationPlaces();
    const [coordinates, setCoordinates] = useState<[number, number]>(LUND_COORDINATES);

    if (isLoading) {
        return <CenteredSpinner />;
    }

    return (
        <>
            <PageHeader>
                <CreatePlace>
                    <Button>
                        <Plus className="h-5 w-5 mr-2" />
                        <p>Create New Location</p>
                    </Button>
                </CreatePlace>
            </PageHeader>
            <MapContainer className="h-96 z-0" center={LUND_COORDINATES} zoom={DEFAULT_ZOOM}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {places?.map((place) => (
                    <Marker key={`marker-${place.id}`} position={[place.latitude, place.longitude]}>
                        <Popup>
                            <p className="text-base !m-0">{place.name}</p>
                            <p className="text-sm !m-0">{place.address}</p>
                        </Popup>
                    </Marker>
                ))}
                <MapLocator coordinates={coordinates} />
            </MapContainer>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-4 lg:max-w-7xl mx-auto">
                {places
                    ?.slice()
                    .reverse()
                    .map((place) => <PlaceCard key={place.id} place={place} setCoordinates={setCoordinates} />)}
            </div>
        </>
    );
};
