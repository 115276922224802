import { errorToast } from '@/components/ui/use-toast';
import { type ClassValue, clsx } from 'clsx';
import { useRef } from 'react';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]): string {
    return twMerge(clsx(inputs));
}

export function multiCompare<T>(a: T, as: T[]): boolean {
    return as.includes(a);
}

export function kebabCase(input: string): string {
    return input
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .replace(/[\s_]+/g, '-')
        .toLowerCase();
}

export const formatDateTimeForForm = (date: string | undefined | null): string | undefined => {
    if (!date) {
        return undefined;
    }
    const d = new Date(date);
    const year = d.getFullYear();
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // +1 because months are 0-indexed
    const hour = String(d.getHours()).padStart(2, '0');
    const minute = String(d.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}T${hour}:${minute}`;
};

export const toISO = <T extends string | undefined | null>(date: T): T extends string ? string : null => {
    if (!date) {
        return null as T extends string ? string : null;
    }
    const d = new Date(date);
    return d.toISOString() as T extends string ? string : null;
};

export const formatDate = (date: string): string => {
    const d = new Date(date);
    const year = d.getFullYear();
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // +1 because months are 0-indexed
    const hour = String(d.getHours()).padStart(2, '0');
    const minute = String(d.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hour}:${minute}`;
};

export const formatDateWithoutTime = (d: Date | string): string => {
    const date = new Date(d);
    const year = date.getFullYear();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 because months are 0-indexed

    return `${year}-${month}-${day}`;
};

export const formatTime = (d: Date | string): string => {
    const date = new Date(d);
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');

    return `${hour}:${minute}`;
};

export const formatPrice = (price: number): string => {
    return (price / 100).toLocaleString('sv-SE', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });
};

export const checkExhaustiveSwitch = (value: never): void => {
    errorToast(`Unhandled value: ${value}`);
};

/**
 * @param enumType The enum to get the key value pairs from
 * @returns An array of key value tuples
 */
export const getEnumKeyValuePairs = <T extends object>(enumType: T): [string, T[keyof T]][] => {
    return Object.keys(enumType)
        .filter((key) => isNaN(Number(key))) // Filter out the numeric keys
        .map((key) => [key, enumType[key as keyof typeof enumType]] as [string, T[keyof T]]);
};

export const getEventBannerSrc = (eventId: string): string => {
    return `${import.meta.env.VITE_IMAGE_STORAGE_URL}/banners/${eventId}/large`;
};

export const getOrganizationPictureSrc = (organizationId: string): string => {
    return `${import.meta.env.VITE_IMAGE_STORAGE_URL}/organization-pictures/${organizationId}/large`;
};

export const getOrganizationBannerSrc = (organizationId: string): string => {
    return `${import.meta.env.VITE_IMAGE_STORAGE_URL}/organization-banners/${organizationId}/large`;
};

export const useFunction0Ref = <R>(fn: () => R): (() => R) => {
    const internal = useRef(fn);
    internal.current = fn;
    const external = useRef((): R => {
        return internal.current();
    });
    return external.current;
};

export const EVENT_BANNER_PLACEHOLDER_SRC = '/images/banner-placeholder.svg';
export const EVENT_BANNER_PLACEHOLDER_BLURHASH = 'L5TR.zxughwc%MenenemhLg4fQeT';
export const EVENT_BANNER_ASPECT_RATIO = 2.63 / 1;

export const ORGANIZATION_PICTURE_PLACEHOLDER_SRC = '/images/picture-placeholder.svg';
export const ORGANIZATION_PICTURE_PLACEHOLDER_BLURHASH = 'L-L;mej[~qj[xufQayfQ_3j[IUfQ';
export const ORGANIZATION_PICTURE_ASPECT_RATIO = 1;

export const ORGANIZATION_BANNER_PLACEHOLDER_SRC = '/images/banner-placeholder.svg';
export const ORGANIZATION_BANNER_PLACEHOLDER_BLURHASH = 'L5TR.zxughwc%MenenemhLg4fQeT';
export const ORGANIZATION_BANNER_ASPECT_RATIO = 2.63 / 1;
