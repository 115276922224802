import { pageSizes, type Pagination } from '@/types/Pagination';
import type { Receipt, ReceiptSmall, ReceiptFilter } from '@/types/Receipt';
import { api } from './rootApi';

export const receiptsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getReceipts: builder.query<Pagination<ReceiptSmall>, ReceiptFilter>({
            query: ({ pageNumber = 0, pageSize = pageSizes.small, organizationId }) =>
                `receipts?pageNumber=${pageNumber}&pageSize=${pageSize}&organizationId=${organizationId}`,
            providesTags: ['Receipt'],
        }),
        getReceiptById: builder.query<Receipt, string>({
            query: (id) => `receipts/${id}`,
            providesTags: ['Receipt'],
        }),
    }),
});

export const { useGetReceiptsQuery, useGetReceiptByIdQuery } = receiptsApi;
