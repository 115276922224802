import { CircleSlashIcon } from 'lucide-react';
import { Input, TextArea } from './input';
import type { Control, FieldValues, Path } from 'react-hook-form';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from './select';
import { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from './form';
import { MultiSelect, type OptionType } from './multi-select';
import { DateTimePicker } from './date-picket';

interface FormInputProps<T extends FieldValues> {
    id: Path<T>;
    label?: string;
    control: Control<T>;
    type?: string;
    error?: string;
    notRequired?: boolean;
    step?: string;
    minValue?: number;
    maxValue?: number;
    hide?: boolean;
    maxLength?: number;
    placeholder?: string;
    accept?: string;
    className?: string;
    onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FormInput = <T extends FieldValues>({
    id,
    label,
    control,
    type,
    error,
    notRequired,
    step,
    minValue,
    hide,
    maxLength,
    maxValue,
    placeholder,
    accept,
    className,
    onChange,
}: FormInputProps<T>): JSX.Element => {
    const name = id.toString();
    return (
        <FormField
            name={id}
            control={control}
            rules={{ required: !notRequired, min: minValue, max: maxValue }}
            render={({ field }) => (
                <FormItem
                    className={cn('w-full transition-opacity duration-500', className, {
                        'opacity-0 pointer-events-none': hide,
                        'opacity-100': !hide,
                    })}
                >
                    {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
                    <FormControl>
                        <Input
                            type={type}
                            id={name}
                            step={step}
                            {...field}
                            value={field.value ?? ''}
                            placeholder={placeholder}
                            onChange={(e) => {
                                if (type === 'number' && !!e.target.value) {
                                    field.onChange(e.target.valueAsNumber);
                                } else {
                                    field.onChange(e.target.value);
                                }
                                onChange?.(e);
                            }}
                            autoComplete="off"
                            maxLength={maxLength}
                            hidden={hide}
                            accept={accept}
                            className={className}
                        />
                    </FormControl>
                    <FormMessage />
                    {error && (
                        <div className="flex text-sm text-red-500 items-center space-x-1 mt-1">
                            <CircleSlashIcon className="h-5 w-5" />
                            <p>{error}</p>
                        </div>
                    )}
                </FormItem>
            )}
        />
    );
};

interface FormTextAreaProps<T extends FieldValues> {
    id: keyof T;
    label: string;
    error?: string;
    notRequired?: boolean;
    control: Control<T>;
    fadeIn?: boolean;
    placeholder?: string;
}

export const FormTextArea = <T extends FieldValues>({
    id,
    label,
    control,
    error,
    notRequired,
    fadeIn,
    placeholder,
}: FormTextAreaProps<T>): JSX.Element => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const name = id.toString();
    return (
        <FormField
            name={id as Path<T>}
            control={control}
            rules={{ required: !notRequired }}
            render={({ field }) => (
                <FormItem
                    className={cn('w-full transition-opacity duration-500', {
                        'opacity-0': !isVisible && fadeIn,
                        'opacity-100': isVisible || !fadeIn,
                    })}
                >
                    <FormLabel htmlFor={name}>{label}</FormLabel>
                    <FormControl>
                        <TextArea id={name} rows={4} {...field} value={field.value ?? ''} placeholder={placeholder} />
                    </FormControl>
                    <FormMessage />
                    {error && (
                        <div className="flex text-sm text-red-500 items-center space-x-1 mt-1">
                            <CircleSlashIcon className="h-5 w-5" />
                            <p>{error}</p>
                        </div>
                    )}
                </FormItem>
            )}
        />
    );
};

interface FormDatePickerProps<T extends FieldValues> {
    id: keyof T;
    label: string;
    error?: string;
    notRequired?: boolean;
    control: Control<T>;
    placeholder?: string;
    fadeIn?: boolean;
    hide?: boolean;
}

export const FormDatePicker = <T extends FieldValues>({
    id,
    label,
    error,
    notRequired,
    control,
    placeholder,
    fadeIn,
    hide,
}: FormDatePickerProps<T>): JSX.Element => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const name = id.toString();
    return (
        <FormField
            name={id as Path<T>}
            control={control}
            rules={{ required: !notRequired }}
            render={({ field }) => (
                <FormItem
                    className={cn('w-full transition-opacity duration-500 flex flex-col', {
                        'opacity-0': (!isVisible && fadeIn) || hide,
                        'opacity-100': (isVisible || !fadeIn) && !hide,
                    })}
                >
                    <FormLabel htmlFor={name} className="text-lg font-semibold">
                        {label}
                    </FormLabel>
                    <FormControl>
                        <DateTimePicker date={field.value} setDate={field.onChange} placeholder={placeholder} />
                    </FormControl>
                    <FormMessage />
                    {error && (
                        <div className="flex text-sm text-red-500 items-center space-x-1 mt-1">
                            <CircleSlashIcon className="h-5 w-5" />
                            <p>{error}</p>
                        </div>
                    )}
                </FormItem>
            )}
        />
    );
};

interface FormSelectProps<T extends FieldValues> {
    id: Path<T>;
    label?: string;
    placeholder?: string;
    error?: string;
    notRequired?: boolean;
    valuesAndNames: [string, string][];
    defaultValueAndName?: [string, string];
    control: Control<T>;
    fadeIn?: boolean;
    onChange?: (value: string) => void;
    className?: string;
}

export const FormSelect = <T extends FieldValues>({
    id,
    label,
    placeholder,
    control,
    error,
    notRequired,
    valuesAndNames,
    defaultValueAndName,
    fadeIn,
    onChange,
    className,
}: FormSelectProps<T>): JSX.Element => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const name = id.toString();
    return (
        <FormField
            name={id}
            control={control}
            rules={{ required: !notRequired }}
            render={({ field }) => (
                <FormItem
                    className={cn('w-full transition-opacity duration-500', className, {
                        'opacity-0': !isVisible && fadeIn,
                        'opacity-100': isVisible || !fadeIn,
                    })}
                >
                    {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
                    <Select
                        onValueChange={(value) => {
                            field.onChange(value);
                            onChange?.(value);
                        }}
                        value={field.value}
                    >
                        <FormControl>
                            <SelectTrigger className="w-full" id={name}>
                                <SelectValue
                                    placeholder={defaultValueAndName?.[1] ?? placeholder}
                                    defaultValue={defaultValueAndName?.[0]}
                                />
                            </SelectTrigger>
                        </FormControl>
                        <SelectContent {...field}>
                            <SelectGroup>
                                {valuesAndNames.map(([value, displayName]) => (
                                    <SelectItem key={value + '-' + displayName} value={value}>
                                        {displayName}
                                    </SelectItem>
                                ))}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                    <FormMessage />
                    {error && (
                        <div className="flex text-sm text-red-500 items-center space-x-1 mt-1">
                            <CircleSlashIcon className="h-5 w-5" />
                            <p>{error}</p>
                        </div>
                    )}
                </FormItem>
            )}
        />
    );
};

interface FormMultiSelectProps<T extends FieldValues> {
    id: keyof T;
    label: string;
    error?: string;
    notRequired?: boolean;
    valuesAndNames: OptionType[];
    control: Control<T>;
    fadeIn?: boolean;
    placeholder?: string;
    hide?: boolean;
}

export const FormMultiSelect = <T extends FieldValues>({
    id,
    label,
    error,
    notRequired,
    valuesAndNames,
    control,
    fadeIn,
    placeholder,
    hide,
}: FormMultiSelectProps<T>): JSX.Element => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const name = id.toString();
    return (
        <FormField
            name={id as Path<T>}
            control={control}
            rules={{ required: !notRequired }}
            render={({ field }) => (
                <FormItem
                    className={cn('w-full transition-opacity duration-500', {
                        'opacity-0': !isVisible && fadeIn,
                        'opacity-100': isVisible || !fadeIn,
                        'pointer-events-none opacity-0': hide,
                    })}
                >
                    <FormLabel htmlFor={name}>{label}</FormLabel>
                    <MultiSelect options={valuesAndNames} selected={field.value} {...field} placeholder={placeholder} />
                    <FormMessage />
                    {error && (
                        <div className="flex text-sm text-red-500 items-center space-x-1 mt-1">
                            <CircleSlashIcon className="h-5 w-5" />
                            <p>{error}</p>
                        </div>
                    )}
                </FormItem>
            )}
        />
    );
};
