import { useEffect, useState } from 'react';
import type { CellContext, ColumnDef, ColumnFiltersState, PaginationState } from '@tanstack/react-table';
import { DataTable } from '@/components/dataTable/DataTable';
import { CenteredSpinner } from '@/components/ui/spinner';
import { useGetReceiptsQuery } from '@/services/receiptsApi';
import { pageSizes } from '@/types/Pagination';
import type { ReceiptSmall } from '@/types/Receipt';
import { formatDate, formatPrice } from '@/lib/utils';
import { useCurrentOrganization } from '@/hooks/apiHooks';
import type { TicketSmall } from '@/types/Ticket';
import { ReceiptDetailsModal } from './Receipt';

export const columns: ColumnDef<ReceiptSmall>[] = [
    {
        accessorKey: 'id',
        header: 'id',
        cell: (info) => info.getValue(),
    },
    {
        accessorKey: 'user.firstName',
        header: 'First Name',
        cell: (info) => info.getValue(),
    },
    {
        accessorKey: 'user.lastName',
        header: 'Last Name',
        cell: (info) => info.getValue(),
    },
    {
        accessorKey: 'tickets',
        header: 'Total Amount',
        cell: (info: CellContext<ReceiptSmall, unknown>): string => {
            const tickets = info.getValue() as TicketSmall[];
            return `${formatPrice(tickets.reduce((acc, ticket) => acc + ticket.actualPrice, 0))} SEK`;
        },
    },
    {
        accessorKey: 'createdAt',
        header: 'Date',
        cell: (info) => formatDate(info.getValue() as string),
    },
];

export function ReceiptActions({ receipt }: { receipt: ReceiptSmall }): JSX.Element {
    return (
        <div className="flex space-x-2">
            <ReceiptDetailsModal receipt={receipt} />
        </div>
    );
}
export default function AdminReceipts(): JSX.Element {
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: pageSizes.small,
    });
    const [pageCount, setPageCount] = useState(1);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const currentOrganization = useCurrentOrganization();

    const { data, isLoading } = useGetReceiptsQuery(
        {
            pageSize: pagination.pageSize,
            pageNumber: pagination.pageIndex,
            organizationId: currentOrganization?.id,
        },
        {
            skip: !currentOrganization,
        },
    );

    useEffect(() => {
        if (data) {
            setPageCount(Math.ceil(data.totalElements / pagination.pageSize));
        }
    }, [data, pagination]);

    if (isLoading || !data) {
        return <CenteredSpinner />;
    }

    return (
        <>
            <h1 className="text-2xl font-semibold p-4 pb-0">Receipts</h1>
            <DataTable
                columns={columns}
                data={data}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                pageCount={pageCount}
                rowActions={(receipt: ReceiptSmall) => <ReceiptActions receipt={receipt} />}
            />
        </>
    );
}
