import type { PropsWithChildren } from 'react';
import { useIsAdmin } from '@/hooks/apiHooks';

export const RequireAdmin: React.FC<PropsWithChildren> = ({ children }) => {
    const isAdmin = useIsAdmin();

    if (!isAdmin) {
        return <p>Not admin</p>;
    }

    return children;
};
