import { useState } from 'react';
import { Button } from '@/components/ui/button';
import { FormInput, FormTextArea, FormSelect } from '@/components/ui/FormHelpers';
import { XIcon } from 'lucide-react';
import { Form } from '@/components/ui/form';
import { useSubmitJobApplicationMutation } from '@/services/jobApplicationApi';
import { errorToastFromCatch, successToast } from '@/components/ui/use-toast';
import { useForm } from 'react-hook-form';
import type { JobApplicationForm } from '@/types/JobApplication';
import { Label } from '@radix-ui/react-dropdown-menu';
import Dropzone, { type SmallFile } from '@/components/ui/Dropzone';

const areas: [string, string][] = [
    ['board', 'Board Positions (Chairman, Vice Chairman, Financial Manager, IT Manager, etc.)'],
    ['marketing', 'Marketing (writing, design, photo and film)'],
    ['finance', 'Finance and Accounting'],
    ['sales', 'Sales'],
    ['legal', 'Legal'],
    ['vieri', 'Vieri'],
    ['development', 'System Development/Programming'],
    ['other', 'Other'],
];

export const JobApplication = (): JSX.Element => {
    const [submitJobApplication] = useSubmitJobApplicationMutation();
    const [submitted, setSubmitted] = useState(false);
    const [file, setFile] = useState<SmallFile | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const form = useForm<JobApplicationForm>({
        defaultValues: {
            name: '',
            email: '',
            phone: '',
            interestedArea: '',
            experience: '',
            motivation: '',
        },
    });

    const errors = form.formState.errors;
    const control = form.control;

    const handleSubmit = form.handleSubmit;
    const submit = handleSubmit(async (data) => {
        setIsSubmitting(true);

        const resumeBase64 = file?.data.startsWith('data:') ? file.data.split(',')[1] : file?.data;

        try {
            await submitJobApplication({
                ...data,
                resume: resumeBase64,
            }).unwrap();

            successToast('Application submitted successfully');
            setSubmitted(true);
        } catch (error) {
            errorToastFromCatch(error);
        } finally {
            setIsSubmitting(false);
        }
    });

    const handleRemoveFile = () => {
        setFile(null);
    };

    if (submitted) {
        return (
            <main className="w-full max-w-3xl mx-auto px-4 md:px-6 py-12 md:py-24">
                <section className="text-center space-y-6">
                    <h1 className="text-3xl font-bold tracking-tight sm:text-4xl">Thank You for Your Interest!</h1>
                    <p className="text-gray-500 dark:text-gray-400">
                        We'll review your application and get back to you soon.
                    </p>
                </section>
            </main>
        );
    }

    return (
        <div className="w-full max-w-3xl mx-auto px-4 md:px-6 py-12 md:py-24">
            <Form {...form}>
                <form onSubmit={submit} className="space-y-8">
                    <div className="space-y-4">
                        <h1 className="text-3xl font-bold tracking-tight sm:text-4xl">Join Our Journey</h1>
                        <span className="block h-2 w-20 bg-primary-500 rounded-full" />

                        <div className="space-y-4 text-gray-500 dark:text-gray-400">
                            <p className="text-lg font-medium">
                                We're in our early stages - a perfect time to make a real impact!
                            </p>

                            <p>Join us in building something extraordinary. Whether you're interested in:</p>

                            <ul className="list-disc pl-6 space-y-2">
                                <li>Board positions (open to all)</li>
                                <li>Marketing and communication</li>
                                <li>Finance and accounting</li>
                                <li>Sales and business development</li>
                                <li>Legal matters</li>
                                <li>Vieri</li>
                                <li>System development and programming</li>
                            </ul>

                            <p>
                                Your unique perspective and skills could help shape our future. We welcome applications
                                from all backgrounds and experience levels.
                            </p>
                        </div>
                    </div>

                    <div className="space-y-6">
                        <FormInput
                            control={control}
                            id="name"
                            type="text"
                            label="Name"
                            error={errors.name?.type === 'required' ? 'Name is required' : undefined}
                        />

                        <FormInput
                            control={control}
                            id="email"
                            type="email"
                            label="Email"
                            error={errors.email?.type === 'required' ? 'Email is required' : undefined}
                        />

                        <FormInput
                            control={control}
                            id="phone"
                            type="tel"
                            label="Phone"
                            error={errors.phone?.type === 'required' ? 'Phone is required' : undefined}
                        />

                        <FormSelect
                            control={control}
                            id="interestedArea"
                            label="Area of Interest"
                            valuesAndNames={areas}
                            error={errors.interestedArea?.type === 'required' ? 'Please select an area' : undefined}
                        />

                        <FormTextArea
                            control={control}
                            id="experience"
                            label="Relevant Experience"
                            placeholder="Tell us about your background and experiences that you think would be valuable..."
                            error={errors.experience?.type === 'required' ? 'Please share your experience' : undefined}
                        />

                        <FormTextArea
                            control={control}
                            id="motivation"
                            label="Why Are You Interested?"
                            placeholder="What motivates you to join us? How would you like to contribute?"
                            error={errors.motivation?.type === 'required' ? 'Please share your motivation' : undefined}
                        />

                        <div className="space-y-2">
                            <Label>Resume/CV (Optional)</Label>
                            <Dropzone
                                text="Drop your resume here or click to upload"
                                accept={{
                                    'application/pdf': ['.pdf'],
                                    'application/msword': ['.doc'],
                                }}
                                maxFiles={1}
                                setFile={setFile}
                            />
                            {file?.name && (
                                <div className="flex items-center justify-between p-2 border border-gray-300 rounded-md">
                                    <span className="text-sm text-gray-600 dark:text-gray-400">{file?.name}</span>
                                    <button
                                        type="button"
                                        onClick={handleRemoveFile}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        <XIcon className="w-5 h-5" />
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex justify-end pt-6">
                        <Button type="submit" disabled={isSubmitting} className="w-full sm:w-auto">
                            {isSubmitting ? 'Submitting...' : 'Submit Application'}
                        </Button>
                    </div>
                </form>
            </Form>
        </div>
    );
};
